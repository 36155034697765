#card-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-size: cover;
  height: auto;
  margin: 80px 0;
}

#text {
  width: auto;
  max-width: 75%;
  padding: 1rem;
}

#text h3 {
  text-align: center;
  font-weight: 600;
  font-size: 1.5em;
  margin: 10px;
}

#cards-div h3 {
  text-align: center;
  margin: 0 auto;
  width: 90%;
  font-weight: 600;
}


#cards img {
  max-width: 100%;
  height: auto;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.5);
  padding: 0;
  margin: 20px 0 5px 0;
  width: 75%;
}

#cards p {
  font-size: 1.5vw;
}

.Tilt {
  width: 55% !important;
  height: auto !important;
  display: flex;
  align-items: center;
}

.Tilt-inner {
  width: 100% !important;
  text-align: center;
}

#text button {
  background-image: linear-gradient(to right, #282442 25%, #03b6bd 50%, lightgrey 0%);
  border: 2.5px solid;
  padding: 5px 10px;
  border-color: #03b6bd;
}

#cards button p {
  font-size: 1rem;
}

@media screen and (max-width: 800px) {
  #cards img {
    width: 100%;
  }
  #card-inner .Tilt {
    width: 80% !important;
  }
  #cards-div h3 {
  text-align: center;
  margin: 10px auto;
}

}
