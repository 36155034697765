@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600;700;900&display=swap');

body {
  font-family: 'Titillium Web', sans-serif !important;
}

.content, h1 {
  color: #000;
}

#main {
  min-height: 85vh;
  height: 85vh;
  display: flex;
  align-items: center;
  border-bottom: 0px;
  padding: 0rem 3rem;
  margin-bottom: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 100%), url("https://images.unsplash.com/reserve/aOcWqRTfQ12uwr3wWevA_14401305508_804b300054_o.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjI0MX0&auto=format&fit=crop&w=1655&q=80");
  background-position: center;
  background-size: cover;
}

.main-title {
  font-size: 4rem;
  font-weight: 700!important;
  margin: 0 !important;
  text-align: left;
  visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: opacity 1s cubic-bezier(0.5, 0, 0, 1) 0.5s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0.5s;

  @include respond(tab-land) {
    font-size: 4rem;
  }
  @include respond(tab-port) {
    font-size: 3.6rem;
    text-align: center;
  }
  @include respond(phone) {
    font-size: 3.5rem;
    line-height: 1.5;
  }
  @include respond(phone-xs) {
    font-size: 2.8rem;
  }
}

.text-color-main {
 color: #03b6bd;
}

#second-line h1 {
  font-size: 50px !important;
  font-weight: 700 !important;
  width: 60%;
}

#second-line h2 {
  font-size: 36px;
  font-weight: 600;
  width: 80%;
  margin-bottom: 15px;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

a:hover {
  color: inherit !important;
  text-decoration: none !important;
}

#about {
  background: linear-gradient(to right, #282442 0%, #03b6bd 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  height: 100%;
  padding: 3rem 0rem 8rem 0rem;
}

#about img {
  width: 25%;
  height: auto;
  box-shadow: 10px 5px 5px rgba(0,0,0,0.5);
  margin-bottom: 20px;
}

.about-me {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

#about-me-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  color: white;
  margin: 5px;
  line-height: 1.5em;
}

#about-me-text button {
  background: transparent;
  color: white;
  border: 3px solid white;
}

#about-me-text button:hover {
  background-color: white;
  color: #03b6bd;
  border-color: #03b6bd;
  transition: .9s;
}

#about h1 {
  margin-bottom: 15px;
}

.about-me p {
  font-size: 1.5vw;
}

#contact {
  background: linear-gradient(to right, #282442 0%, #03b6bd 100%);
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
  padding: 70px 0 20px 0;
  color: white;
}

#contact h3 {
  color: white;
  margin: 0 !important;
}

#contact button {
  background: transparent;
  color: white;
  border: 3px solid white;
  margin-top: 25px;
  font-size: 25px !important;
  padding: 5px 20px;
}

#contact button:hover {
  background-color: white;
  color: #03b6bd;
  border-color: #03b6bd;
  transition: .9s;
}

#contact-inner {
  margin: 110px 0 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact-me {
  width: 45%;
  text-align: center;
}

#card1 {
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%) !important;
  padding: 75px 0 75px 15px;
  background-color: lightgrey;
}

#card2 {
  padding: 75px 0 75px 15px !important;
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%) !important;
  background-color: lightgrey;
}

p {
  margin-bottom: 0.7rem !important;
}

@media screen and (min-width: 801px) {
  h1 {
    font-size: 54px !important;
  }
  .content h1 {
    font-size: 80px !important;
  }
  h3 {
    font-size: 36px !important;
  }
  p {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 30px !important;
  }
  .content h1 {
    font-size: 50px !important;
  }
  #second-line h2 {
    font-size: 30px !important;
    margin-top: 10px;
    width: 100%;
  }
  h3 {
    font-size: 38px !important;
    font-weight: 600 !important;
  }
  p {
    font-size: 16px !important;
    line-height: 20px;
  }
  #main button {
    padding: 7px 15px 7px 15px !important;
  }
  #main button p {
    font-size: 18px !important;
  }
  #about img {
    width: 40%;
    height: auto;
    margin: 20px 0;
  }
  #about-me-text {
    width: 85% !important;
  }
  #card1, #card2 {
    padding: 60px 0 65px 0 !important;
  }
  #contact {
    padding: 10px 0 20px 0 !important;
  }
  #footer-logo {
    font-size: 30px !important;
  }
  #cards img {
    margin: 0 !important;
  }
  #cards button p {
    font-size: 10px !important;
  }
  #card-inner {
    margin: 30px 0 5px 0 !important;
  }
  #contact-inner {
    margin: 75px 0 0 0 !important;
  }
  #contact p {
    margin-bottom: 5px;
  }
  #contact-me {
    margin-top: 0;
    width: 85%;
  }
  #contact button {
    margin-top: 5px;
    padding: 7px 20px 7px 20px;
    border-width: 2px;
    font-size: 20px !important
  }
  .form-control {
    height: 1rem !important;
    font-size: .6rem !important;
  }
  form label {
    margin: 0 !important;
    font-size: 16px !important;
  }
  form input {
    line-height: 1.1rem !important;
    font-size: 16px !important;
    padding: 0 3px !important;
  }
  textarea {
    height: 2.5rem !important;
    font-size: 10px !important;
  }
  #text {
  max-width: 100% !important;
  padding: 1rem 2rem !important;
  }
  #my-form {
    width: 75% !important;
  }
  .form-item {
    margin-bottom: 10px;
  }
  textarea {
    height: 100px !important;
  }
  #footer-inner {
    width: 70%;
  }
}

#carat a {
  position: fixed;
  right: 15px;
  bottom: 30px;
  background: gray;
  border-radius: 82px;
  height: min-content;
  padding: 10px;
}

#carat p {
  margin-bottom: 0 !important;
  color: white !important;
}

#carat p svg {
  height: 40px;
  width: 40px;
  color: white !important
}

button p {
  margin-bottom: 0 !important;
}


#contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact-title {
  text-align: center;
  margin: 0;
}
