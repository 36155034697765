form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  width: 100%;
  line-height: 1rem !important;
  padding: 3px 10px;
  border-width: 0px;
  border-radius: 3px;
}

input:focus {
  outline: none;
}

label {
  font-size: 20px;
  margin-top: .5rem;
  margin-bottom: 0.3rem;
}

textarea {
  width: 100%;
  padding: 10px 10px;
  border-radius: 3px;
  height: 6rem;
  border-width: 0px;
  border-radius: 3px;
}

textarea:focus {
  outline: none;
}

#form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#form-inputs {
  width: 100%;
}

.form-item {
  width: 100%
}

#my-form {
  width: 40%;
}
