#footer {
  background: linear-gradient(90deg,#282442 0,#03b6bd);
  color: white;
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  margin-top: -1px;
}

#footer-inner {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  padding: 5px;
}
#footer-logo {
  height: 100%;
  font-size: 50px;
}

#footer a :hover {
  color: #03b6bd;
}

#caption p {
  color: white;
  font-size: 15px;
  margin-bottom: 0 !important;
}
